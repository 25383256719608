import {Injectable} from '@angular/core';
import {APIService, CalendarEventSerializer} from './api.service';
import {Observable, ReplaySubject, throwError} from 'rxjs';
import {catchError, map, share, shareReplay, switchMap, tap} from 'rxjs/operators';
import {CalendarEvent, CalendarEventLike} from '../models/calendar-event';
import {PhysicianTeam} from '../models/physician-team';
import {UserAuthService} from './user-auth.service';
import {SlidePanelService} from '../portal/slide-panel/slide-panel.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {ConfirmService} from '../portal/@portal-shared/confirm/confirm.service';
import {ToastService} from './toast.service';
import {assignOrCreate} from '../models/model-base';
import {HttpClient} from '@angular/common/http';
import {PARAMETERS} from './parameters';
import {extractErrorMessage} from '../utils/error.utils';

type GoogleCalendarHoliday = {
    items: Holiday[];
};

type Holiday = {
    end: {
        date: string;
    };
    start: {
        date: string;
    };
    summary: string;
};

@Injectable({
    providedIn: 'root',
})
export class CalendarService {
    refreshCalendarEvents$ = new ReplaySubject<void>();
    private _physicianTeams$?: Observable<PhysicianTeam[]>;
    private _holidaysCache: Observable<CalendarEventLike[]>;

    constructor(private apiService: APIService,
                private userAuth: UserAuthService,
                private toastService: ToastService,
                private slidePanelService: SlidePanelService,
                private permissionsService: NgxPermissionsService,
                private confirmService: ConfirmService,
                private httpClient: HttpClient) {
        this.refreshCalendarEvents();
    }

    get physicianTeams$(): Observable<PhysicianTeam[]> {
        if (!this._physicianTeams$) this._physicianTeams$ = this.userAuth.physicianTeams$.pipe(shareReplay(1));
        return this._physicianTeams$;
    }

    getEvents$(filter): Observable<CalendarEvent[]>{
        return this.apiService.CalendarEventViewSet.list(filter)
            .pipe(map(calendarEvents => calendarEvents.map(ce => assignOrCreate(CalendarEvent, ce))));
    }

    private _convertCalendarEventToCalendarEventLike(holidays: Holiday[]): CalendarEventLike[] {
        return holidays.map(holiday => {
            const calendarEventHoliday: CalendarEventLike = {
                date: holiday.start.date,
                type: 'HOLIDAY',
                end_date: holiday.end.date,
                name: holiday.summary,
            };

            return calendarEventHoliday;
        });
    }

    getEventById$(calendarEvent: CalendarEvent | number) {
        const cId = typeof calendarEvent === 'number' ? calendarEvent : calendarEvent.id;
        return this.getEvents$({id: cId});
    }

    saveEvent(data): Observable<CalendarEvent> {
        if (!(data.date && data.type && data.user)) {
            this.toastService.warning('A Date, a Physician and a Type has to be provided when creating an Event');
            return null;
        }
        const obs$ = (data.id ?
            this.apiService.CalendarEventViewSet.partial_update(data.id, data) :
            this.apiService.CalendarEventViewSet.create(data)).pipe(
            tap(() => {
                this.toastService.buttonToast(
                    'success',
                    () => {
                        const {id, date, end_date, ...newEventData} = data;
                        this.openCreateEvent({
                            team: newEventData.user_facility,
                            user: newEventData.user,
                            type: newEventData.type,
                        });
                    },
                    'Event has been successfully created.',
                    'Create Another',
                    'Event Created',
                );
            },
            err => this.toastService.error(err, extractErrorMessage(err, 'save Event'))
            ),
            map((res: CalendarEventSerializer) => {
                this.refreshCalendarEvents();
                return assignOrCreate(CalendarEvent, res);
            }),
            share()
        );
        obs$.subscribe();
        return obs$;
    }

    removeEvent(event: CalendarEvent) {
        const obs$ = this.confirmService.requestConfirm({
            title: 'Remove Event',
            body: 'Are you sure you want to remove this Event?',
        }).pipe(
            switchMap(() => this.apiService.CalendarEventViewSet.destroy(event.id)),
            catchError(err => {
                this.toastService.error(extractErrorMessage(err, 'remove Event'));
                return throwError(err);
            }),
            tap(() => {
                this.toastService.success('Event was removed successfully');
                this.refreshCalendarEvents();
            }),
            share(),
        );
        obs$.subscribe();
        return obs$;
    }

    openCreateEvent(partialData?: any) {
        if (!this.permissionsService.getPermission('PERMISSION_WRITE_VISITS')) return;

        this.slidePanelService.addComponent('CALENDAR_EVENT', null, null, false, {
            componentInputs: partialData,
        });
    }

    openEditEvent(event: CalendarEvent) {
        const date = Object.getPrototypeOf(event) instanceof CalendarEvent ? Object.getPrototypeOf(event).date : event.date;
        this.slidePanelService.addComponent('CALENDAR_EVENT', event.id, null, false, {
            componentInputs: {
                id: event.id,
                date,
                endDate: event.end_date,
                type: event.type,
                team: event.physicianTeam,
                user: event.user,
            },
        });
    }

    refreshCalendarEvents() {
        this.refreshCalendarEvents$.next();
    }

    getCalendarHolidays$(): Observable<CalendarEventLike[]> {
        if (!this._holidaysCache) {
            const holidaysEndpoint = `https://www.googleapis.com/calendar/v3/calendars/en.usa%23holiday%40group.v.calendar.google.com/events?key=${PARAMETERS.google_api_key}`;
            this._holidaysCache = this.httpClient.get<GoogleCalendarHoliday>(holidaysEndpoint).pipe(
                map(x => this._convertCalendarEventToCalendarEventLike(x.items)),
                shareReplay(1));
        }
        return this._holidaysCache;
    }
}
