import {CalendarEventSerializer} from '../@core/api.service';
import {ModelBase} from './model-base';
import {PhysicianTeam, PhysicianTeamUser} from './physician-team';
import {Modify} from '../utils/type.utils';

export type CalendarEventType = 'NO_APPOINTMENT' | 'CALL_COVERAGE' | 'HALF_DAY' | 'FACILITY_MEETING' | 'HOSPICE_MEETING' | 'HOLIDAY';

export type CalendarEventLike = Pick<CalendarEvent, 'type' | 'end_date' | 'date'> & {name: string};

export class CalendarEvent extends ModelBase implements Modify<CalendarEventSerializer, {user_facility: any}> {
    id: number;
    type: CalendarEventType;
    date: Date | string;
    end_date: Date | string;
    user: PhysicianTeamUser;
    user_facility: PhysicianTeam;

    static typeOptions: {id: CalendarEventType; name: string; singleDay?: boolean; needsPhysicianTeam?: boolean}[] = [
        {id: 'NO_APPOINTMENT', name: 'No Appointment'},
        {id: 'CALL_COVERAGE', name: 'Call Coverage'},
        {id: 'HALF_DAY', name: 'Half Day'},
        {id: 'FACILITY_MEETING', name: 'Facility Meeting', singleDay: true, needsPhysicianTeam: true},
        {id: 'HOSPICE_MEETING', name: 'Hospice Meeting', singleDay: true, needsPhysicianTeam: false},
    ];

    private static _singleDayTypes = CalendarEvent.typeOptions.filter(x => x.singleDay).map(x => x.id);
    private static _needsPhysicianTeamTypes = CalendarEvent.typeOptions.filter(x => x.needsPhysicianTeam).map(x => x.id);

    get typeText() {
        return CalendarEvent.typeOptions.find(x => x.id === this.type)?.name || '';
    }

    get physicianTeam() {
        return this.user_facility;
    }

    get canBeMultiDay() {
        return CalendarEvent.canBeMultiDay(this.type);
    }

    static canBeMultiDay(type: CalendarEventType) {
        return type && !this._singleDayTypes.includes(type);
    }

    static needsPhysicianTeam(type: CalendarEventType) {
        return this._needsPhysicianTeamTypes.includes(type);
    }

    assign(x: CalendarEventSerializer) {
        super.assign(x);

        this.setAccessor('user_facility', PhysicianTeam);
        this.setAccessor('user', PhysicianTeamUser);
    }
}
